<template>
  <div class="container">
    <h1 class="title">北京绘文互通科技有限公司</h1>
    <h2 class="des">
      我们专注于娱乐小游戏及个性化产品的开发。
      通过创造有趣和创新性的产品体验为用户带来娱乐和放松。
    </h2>
    <p class="addr">
      地址：北京市朝阳区祁家豁子2号（南院）10号楼五层536室
    </p>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.title{
  width: 300px;
  color: black;
}
.des{
  margin-top: 100px;
  width: 500px;

}
.container{
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;

}
.addr{
  margin-top: 150px;
}
</style>
